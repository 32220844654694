"use client";

import Head from "next/head";
import { ShellContainer } from "nftx-core";
import { Grid, Column } from "@carbon/react";

import Leadspace from "@/src/components/Landing/Leadspace";
import NftxCarousel from "@/src/components/Landing/NftxCarousel";

export default function Home() {
  return (
    <>
      <Head>
        <title>NFTxLedger</title>
        <meta name="description" content="A Ledger for NFTx" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <ShellContainer>
        <Grid>
          <Column lg={15}></Column>
        </Grid>
        <Leadspace />
        <NftxCarousel />
      </ShellContainer>
    </>
  );
}
