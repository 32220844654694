import { ArrowRight } from "@carbon/icons-react";

import C4DCard from "@carbon/ibmdotcom-web-components/es/components-react/card/card.js";
import C4DCardHeading from "@carbon/ibmdotcom-web-components/es/components-react/card/card-heading.js";
import C4DCardFooter from "@carbon/ibmdotcom-web-components/es/components-react/card/card-footer.js";
import C4DContentSectionHeading from "@carbon/ibmdotcom-web-components/es/components-react/content-section/content-section-heading.js";
import C4DLinkWithIcon from "@carbon/ibmdotcom-web-components/es/components-react/link-with-icon/link-with-icon.js";
import C4DCarousel from "@carbon/ibmdotcom-web-components/es/components-react/carousel/carousel.js";
import C4DCardSectionCarousel from "@carbon/ibmdotcom-web-components/es/components-react/card-section-carousel/card-section-carousel.js";

import { Grid, Column, Row } from "@carbon/react";

export default function NftxCarousel() {
  return (
    <C4DCardSectionCarousel
      style={{
        paddingRight: "3rem",
        marginRight: "-3rem",
        position: "relative",
        left: "-3rem",
      }}
    >
      <C4DContentSectionHeading>A selection of NFTx</C4DContentSectionHeading>

      <C4DLinkWithIcon slot="footer" href="/explore">
        Explore NFTx
        <ArrowRight slot="icon" />
      </C4DLinkWithIcon>

      <C4DCarousel>
        <C4DCard href="/item/QmUkVhVXBi7RrspMpCc936QFPohNA7WzFCdZGSPCA3hn6w">
          <img
            alt="Hello World"
            src="img/thumbs/thumb-01.png"
            height="150px"
            width="80px"
            style={{
              position: "relative",
              top: "-30px",
              left: "0",
              height: "250px",
              width: "250px",
            }}
          />

          <C4DCardHeading>Hello World</C4DCardHeading>
          <C4DCardFooter>
            <ArrowRight slot="icon" />
          </C4DCardFooter>
        </C4DCard>

        <C4DCard href="/item/QmYusxrZosTJqG8UB1hybsm9VyDagtYVYwf1Uasbjjtkxj">
          <img
            alt="Decay Photo"
            src="img/thumbs/thumb-02.png"
            height="150px"
            width="80px"
            style={{
              position: "relative",
              top: "-30px",
              left: "0",
              height: "250px",
              width: "250px",
            }}
          />
          <C4DCardHeading>Decay</C4DCardHeading>

          <C4DCardFooter>
            <ArrowRight slot="icon" />
          </C4DCardFooter>
        </C4DCard>

        <C4DCard href="/item/QmTY5gtD6bWa2z2WfLMSa4rreYQYLMvNwfDJerb22HLvvn">
          <img
            alt="Image alt text"
            src="img/thumbs/thumb-03.png"
            height="150px"
            width="80px"
            style={{
              position: "relative",
              top: "-30px",
              left: "0",
              height: "250px",
              width: "250px",
            }}
          />
          <C4DCardHeading>Tokyo</C4DCardHeading>
          <C4DCardFooter>
            <ArrowRight slot="icon" />
          </C4DCardFooter>
        </C4DCard>

        <C4DCard href="/item/QmemxeE8dmySTvaV7s8ka6B61wHxX8YaxCPJwjE8MBafoi">
          <img
            alt="PDF"
            src="img/thumbs/thumb-04.png"
            height="150px"
            width="80px"
            style={{
              position: "relative",
              top: "-30px",
              left: "0",
              height: "250px",
              width: "250px",
            }}
          />
          <C4DCardHeading>PDF</C4DCardHeading>
          <C4DCardFooter>
            <ArrowRight slot="icon" />
          </C4DCardFooter>
        </C4DCard>

        <C4DCard href="/item/QmUY2oTAqiT2f2R7K5Wz9XfRvCrmPThKSREydtUC3aS1pZ">
          <img
            alt="Game of Life"
            src="img/thumbs/thumb-05.png"
            height="150px"
            width="80px"
            style={{
              position: "relative",
              top: "-30px",
              left: "0",
              height: "250px",
              width: "250px",
            }}
          />
          <C4DCardHeading>Game of Life</C4DCardHeading>
          <C4DCardFooter>
            <ArrowRight slot="icon" />
          </C4DCardFooter>
        </C4DCard>

        <C4DCard href="/item/Qme5iXYu1iRdE7EcGGrvXeAcjquK1AUCDeuiFaELZoPi9r">
          <img
            alt="3D Model"
            src="img/thumbs/thumb-06.png"
            height="150px"
            width="80px"
            style={{
              position: "relative",
              top: "-30px",
              left: "0",
              height: "250px",
              width: "250px",
            }}
          />
          <C4DCardHeading>3D Model</C4DCardHeading>
          <C4DCardFooter>
            <ArrowRight slot="icon" />
          </C4DCardFooter>
        </C4DCard>

        <C4DCard href="/item/QmTTW4GksQiMJvyMLVZqZY1bxDLfvrv7oskrwYDJ9Zpsqx">
          <img
            alt="Breakout Game"
            src="img/thumbs/thumb-07.png"
            height="150px"
            width="80px"
            style={{
              position: "relative",
              top: "-30px",
              left: "0",
              height: "250px",
              width: "250px",
            }}
          />
          <C4DCardHeading>Breakout Game</C4DCardHeading>
          <C4DCardFooter>
            <ArrowRight slot="icon" />
          </C4DCardFooter>
        </C4DCard>
      </C4DCarousel>
    </C4DCardSectionCarousel>
  );
}
