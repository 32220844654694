import { ArrowRight } from "@carbon/icons-react";
import C4DBackgroundMedia from "@carbon/ibmdotcom-web-components/es/components-react/background-media/background-media";
import C4DButtonGroup from "@carbon/ibmdotcom-web-components/es/components-react/button-group/button-group";
import C4DButtonGroupItem from "@carbon/ibmdotcom-web-components/es/components-react/button-group/button-group-item";
import C4DLeadspace from "@carbon/ibmdotcom-web-components/es/components-react/leadspace/leadspace";
import C4DLeadspaceHeading from "@carbon/ibmdotcom-web-components/es/components-react/leadspace/leadspace-heading";

export default function Leadspace() {
  return (
    <C4DLeadspace size="tall">
      <C4DLeadspaceHeading highlight="NFT" type-style="fluid-heading-05">
        EXPLORE A NEW TYPE OF NFT
      </C4DLeadspaceHeading>
      NFTx is a new standard to create extendable and composable NFTs.
      <C4DButtonGroup slot="action">
        <C4DButtonGroupItem href="explore">
          <ArrowRight slot="icon" />
          Explore NFTx
        </C4DButtonGroupItem>
        <C4DButtonGroupItem href="developers">
          <ArrowRight slot="icon" />
          Developers Docs
        </C4DButtonGroupItem>
      </C4DButtonGroup>
      <C4DBackgroundMedia slot="image" alt="Image alt text" opacity="100">
        <div style={{ position: "relative" }}>
          <video
            style={{
              position: "absolute",
              right: "-8rem",
              top: 0,
              height: "auto",
              maskImage:
                "linear-gradient(to left, rgb(0, 0, 0, 0) 10%, rgba(0, 0, 0, 1) 100%)",
            }}
            loop={true}
            autoPlay={true}
            controls
            muted
            id="myVideo"
          >
            <source src="/img/landing.mp4" type="video/mp4" />
          </video>
        </div>
      </C4DBackgroundMedia>
    </C4DLeadspace>
  );
}
